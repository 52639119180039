.rd-3{
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .2rem;
    margin: 5px auto;
    margin-bottom: 15px !important;
    width: 100%;
}

.rd-box{
    width: auto;
    height: auto;
    border: 1px solid #000;
    background-color: #fff;
}

.spacai {
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: .2rem;
    margin-bottom: 15px !important;
    width: 100%;
}

.sp-box {
    width: auto;
    border: 1px solid #000;
    background-color: #fff;
}

@media only screen and (max-width: 500px) {
    .result-item {
        font-size: 20px;
    }

    .result-item-spacai {
        font-size: 20px
    }
    .date{
        font-size: 26px;
    }
}

@media only screen and (max-width: 450px) {
    .result-item {
        font-size: 18px;
    }

    .result-item-spacai {
        font-size: 18px;
    }

    .date{
        font-size: 24px;
    }
}

    @media only screen and (max-width: 400px) {
    .result-item {
        font-size: 16px;
        letter-spacing: 1px;
    }

    .result-item-3 {
        font-size: 22px;
        letter-spacing: 1px;
    }

    .result-item-spacai {
        font-size: 16px;
        letter-spacing: 1px;
    }

    .date{
        font-size: 22px;
    }
}

