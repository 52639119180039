
.result-box{
    background-color: #0d0d33;
    border: 2px solid #40434a;
    border-radius: 10px;
    padding: 10px;
}

.c-number{
    padding: 0 2px;
    background: transparent !important;
    border: 0px !important;
}

.result-head {
    margin-top: 20px;
    margin-bottom: 10px;
}

.result-head h1 {
    text-align: center;
    color: #fff;
    font-size: 2rem;
    margin-bottom: 25px;
    font-weight: 500;
}

.result-countdown{
    text-align: center;
    color: #aaa;
    font-size: 1.2rem;
    letter-spacing: .3rem; 
}

.h-results {
    margin-bottom: 15px;
    color: #fff;
}

.h-results .h-head {
    padding: 0 5px;
    background-color: #a90b03;
    padding: 4px;
    border-radius: 5px;
}

.h-results .h-head h2 {
    color: #fff;
    font-size: 1.2rem;
}

.h-results .h-body {
    margin-top: 10px;
}

.row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-4{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.h-results .h-body .h-box {
    padding: 5px;
    background-color: #dee4ea;
    border-radius: 10px;
    margin: 15px 0 15px 0;
    opacity: .8;
    border: 1px solid #3466cd;
}

.h-results .h-body .h-box .h-date {
    color: #222;
    font-weight: 500;
}

.h-results .h-body .h-box h2 {
    margin: 0;
    color: #111;
    letter-spacing: 2px;
    font-weight: 700;
    text-align: center;
}

.result-head h2 {
    text-align: center;
    font-size: 1.2rem;
    margin: 0;
    font-weight: 700;
    color: #fff;
}

.footer {
    margin-top: 0;
    border-top: 6px solid #a90b03;
    width: 100%;
    text-align: center;
}


.footer .footer-inner {
    text-align: center;
    background-color: #00000b;
    padding: 15px;
    color: #fefefe;
}

.h-results .h-body .h-box h2 {
    margin: 0;
    color: #111;
    letter-spacing: 2px;
    font-weight: 700;
    text-align: center;
    font-size: 2rem;
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
}
@media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 960px;
    }
}
@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 720px;
    }
}
@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 540px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media only screen and (max-width: 767px) {
    .h-results .h-body {
        width: 80%;
        margin: 15px auto;
    }
}