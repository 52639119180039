/* * {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
} */

.now-extra-result {
    width: 450px;
    margin: 30px auto 0;
    border: 5px solid #323d87;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px
}

.now-extra-result .result-extra-head h1 {
    text-align: center;
    color: #d92121;
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: 700
}

.now-extra-result .result-extra-head h2 {
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
    font-weight: 700;
    color: #2325dc;
    line-height: 1.2;
}

.now-extra-result .result-extra-head h2 span {
    background-color: #fff;
    padding: 0 15px;
    border-radius: 5px;
    font-size: 1.5rem;
}

.now-extra-result .result-body {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.now-extra-result .result-body,.now-extra-result .result-body .result-box .c-numbers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.now-extra-result .result-body .result-box .c-numbers .c-extra-number {
    padding: 4px 2px;
    margin: 0 2px;
    background-color: #fff;
    border: 4px solid #d92121;
    border-radius: 8px
}

.result-number-extra {
    width: 60px;
    font-size: 64px;
    line-height: 1;
}

.now-extra-result .result-countdown {
    text-align: center;
    margin-bottom: 5px
}

.now-extra-result .result-countdown span {
    color: #002766;
    font-size: 1.2rem;
    letter-spacing: .1rem;
    background-color: #fff;
    padding: 0 5px;
    border-radius: 5px
}

@media only screen and (max-width: 500px) {
    .now-extra-result {
        width:380px;
        margin: 30px auto 0;
        padding: 10px
    }

    .now-extra-result .result-extra-head h1 {
        font-size: 1.5rem
    }

    .now-extra-result .result-extra-head h2 {
        font-size: 1rem
    }

    .now-extra-result .result-body .result-box {
        padding: 8px
    }

    .now-extra-result .result-body .result-box .c-numbers .c-extra-number {
        padding: 0 2px
    }

    .result-number-extra {
        width: 60px;
        font-size: 52px;
    }
}

@media only screen and (max-width: 400px) {
    .now-extra-result {
        width:320px;
        margin: 20px auto 0;
        padding: 10px
    }

    .now-extra-result .result-extra-head h1 {
        font-size: 1.2rem
    }

    .now-extra-result .result-extra-head h2 {
        font-size: 1rem
    }

    .now-extra-result .result-body .result-box {
        padding: 5px
    }

    .now-extra-result .result-body .result-box .c-numbers .c-extra-number {
        padding: 0 1px
    }

    .result-number-extra {
        width: 40px;
        font-size: 36px;
    }
}

.c-extra-number {
    padding: 4px 2px;
    margin: 0 2px;
    background-color: #fff;
    border: 4px solid #d92121;
    border-radius: 8px;
    color: #212529;
    font-size: 54px;
}

.h-results .h-head {
    padding: 0 5px;
    background-color: #032a67;
    padding: 4px;
    text-align: center;
    border-radius: 0px;
}

.result-extra-head h1 {
    text-align: center;
    color: #fff;
    font-size: 2rem;
    margin-bottom: 25px;
    font-weight: 500;
}

.result-countdown {
    text-align: center;
    color: #aaa;
    font-size: 1.2rem;
    letter-spacing: .3rem;
}

.h-results {
    margin-bottom: 15px;
    color: #fff;
}

.h-results .h-head h2 {
    color: #fff;
    font-size: 1.2rem;
}

.h-results .h-body {
    margin-top: 10px;
    padding: 0 10px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-4 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.h-results .h-body .h-box {
    padding: 5px;
    background-color: #dee4ea;
    border-radius: 10px;
    margin: 15px 0 15px 0;
    opacity: .8;
    border: 1px solid #d92121;
}

.h-results .h-body .h-box .h-date {
    color: #222;
    font-weight: 500;
    text-align: center;
}

.h-results .h-body .row .col-md-4 .h-box h2 {
    margin: 0;
    color: #032a67;
    letter-spacing: 2px;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
}

.h-results .h-body .h-box h2 {
    margin: 0;
    color: #111;
    letter-spacing: 2px;
    font-weight: 700;
    text-align: center;
}

.result-extra-head h2 {
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
    font-weight: 700;
    color: #fff;
}

.footer {
    margin-top: 0;
    border-top: 6px solid #a90b03;
    width: 100%;
    text-align: center;
}


.footer .footer-inner {
    text-align: center;
    background-color: #eee;
    padding: 15px;
    color: #032a67;
}

.h-results .h-body .h-box h2 {
    margin: 0;
    color: #111;
    letter-spacing: 2px;
    font-weight: 700;
    text-align: center;
    font-size: 2rem;
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px;
    }
}

@media (min-width: 992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px;
    }
}

@media (min-width: 768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px;
    }
}

@media (min-width: 576px) {

    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

@media only screen and (max-width: 767px) {
    .h-results .h-body {
        width: 80%;
        margin: 15px auto;
    }
}